import { Badge } from "antd";
import { ServiceOrder } from "../../types";

type ServiceOrderCardStatusBadgeProps = {
  status: ServiceOrder["status"];
};

const ServiceOrderCardStatusBadge: React.FC<ServiceOrderCardStatusBadgeProps> =
  ({ status }) => {
    const configMap: Record<
      ServiceOrder["status"],
      React.ComponentProps<typeof Badge>
    > = {
      pending: { status: "warning", text: "Pendente" },
      solving: { status: "processing", text: "Resolvendo" },
      solved: { color: "lime", text: "Solucionada" },
      closed: { status: "success", text: "Encerrada" },
      refused: { status: "error", text: "Recusada" },
    };

    const config = configMap[status];

    return <Badge {...config} />;
  };

export default ServiceOrderCardStatusBadge;
