import { Layout } from "antd";
import dayjs from "dayjs";
import firebase from "firebase";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { AppContent, AppHeader, GlobalLoading } from "./components";
import { PAGES } from "./constants";
import { useAuth } from "./hooks";
import {
  setAuthUser,
  setDataDepartments,
  setDataLoading,
} from "./store/actions";
import { Na3Dpt } from "./types";

const App: React.FC = () => {
  const { user, signOutAsync } = useAuth();
  const [fbUser, fbUserLoading, fbUserError] = useAuthState(firebase.auth());
  const [fbDpts, fbDptsLoading, fbDptsError] = useCollectionData<
    Na3Dpt,
    "id",
    "ref"
  >(firebase.firestore().collection("departments"), {
    idField: "id",
    refField: "ref",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (fbUserLoading || fbDptsLoading) {
      dispatch(setDataLoading(true));
    } else {
      dispatch(setDataLoading(false));
    }

    if (fbUser) {
      const userExpiresInMs = 10 * 60 * 1000;

      // setTimeout(signOutAsync, userExpiresInMs);

      dispatch(
        setAuthUser({
          user: fbUser,
          expiresAt: dayjs().add(userExpiresInMs, "ms").format(),
        })
      );
    } else {
      dispatch(setAuthUser({ user: fbUser, expiresAt: null }));
    }
    dispatch(setAuthUser({ user: fbUser, expiresAt: null }));

    dispatch(setDataDepartments(fbDpts || []));
  }, [fbUser, fbDpts, fbUserLoading, fbDptsLoading, signOutAsync, dispatch]);

  return (
    <>
      <Layout>
        <AppHeader />

        <AppContent>
          <Switch>
            {PAGES.map(({ path, title, isPublic, Component }) => (
              <Route path={path} key={path}>
                <Helmet>
                  <title>{title ? `Nova A3 • ${title}` : "Nova A3"}</title>
                </Helmet>

                {user || isPublic ? (
                  <Component />
                ) : (
                  <Redirect to="/auth/entrar" />
                )}
              </Route>
            ))}
          </Switch>
        </AppContent>
      </Layout>

      <GlobalLoading />
    </>
  );
};

export default App;
