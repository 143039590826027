import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Row,
} from "antd";
import dayjs from "dayjs";
import firebase from "firebase";
import { useCallback, useMemo, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import styled from "styled-components";
import {
  Empty,
  PageColumn,
  PageContainer,
  TransfLabelPreview,
} from "../../components";
import { collectionId } from "../../helpers";
import { TransfLabelTemplate } from "../../types";

const TemplateCard = styled(Card)`
  border-radius: 6px;
`;

const LabelProductQtyHelp = styled.div`
  text-align: right;
  font-style: italic;
`;

const LabelFooterBtnsContainer = styled.div`
  text-align: right;
`;

const LabelsHomePage: React.FC = () => {
  const [fbLabelTemplates, fbLabelTemplatesLoading, fbLabelTemplatesError] =
    useCollectionData<TransfLabelTemplate, "id", "ref">(
      firebase
        .firestore()
        .collection(
          collectionId("transf-label-templates", { forceProd: true })
        ),
      { idField: "id", refField: "ref" }
    );
  const [selectedTemplate, setSelectedTemplate] =
    useState<TransfLabelTemplate>();
  const [labelConfig, setLabelConfig] = useState({
    batchId: "",
    productQty: 1,
    numOfCopies: 1,
  });
  const [labelBatchIdIsValid, setLabelBatchIdIsValid] = useState<boolean>();
  const [showLabelPreview, setShowLabelPreview] = useState(false);

  const currentDate = useMemo(() => dayjs().format("DD/MM/YYYY"), []);

  const onLabelBatchIdKeyDown = useCallback(
    ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
      if (key === "Backspace") {
        setLabelConfig((curr) => ({
          ...curr,
          batchId: curr.batchId.slice(0, curr.batchId.length - 1),
        }));
        return;
      }

      if (!key.match(/^\w|\d$/)) {
        return;
      }

      const value = (labelConfig.batchId + key).toUpperCase();

      if (!key || (key === "-" && value.indexOf("--") !== -1)) {
        setLabelConfig((curr) => ({ ...curr, batchId: value }));
        return;
      }

      if (value.length > 15) {
        return;
      }

      switch (value.length) {
        case 1:
        case 2:
          if (key.match(/\d/)) return;
          break;
        case 3:
        case 4:
        case 5:
        case 7:
        case 8:
        case 10:
        case 11:
        case 12:
        case 13:
        case 14:
          if (!key.match(/\d/)) return;
          break;
        case 15:
          if (key.match(/\d/)) return;
          break;
      }

      if (value.length === 5 || value.length === 9) {
        setLabelConfig((curr) => ({
          ...curr,
          batchId:
            value.slice(0, value.length - 1) +
            "-" +
            value.slice(value.length - 1),
        }));
        return;
      }

      setLabelConfig((curr) => ({ ...curr, batchId: value }));
    },
    [labelConfig.batchId]
  );

  const handleLabelBatchIdValidation = useCallback(() => {
    return /\w{2}\d{2}-?\d{3}-?\d{5}\w/.test(labelConfig.batchId);
  }, [labelConfig.batchId]);

  const handleShowLabelPreview = useCallback(() => {
    if (!handleLabelBatchIdValidation()) {
      message.error("Corrija os campos com erro");
      setLabelBatchIdIsValid(false);
      return;
    }
    setShowLabelPreview(true);
  }, [handleLabelBatchIdValidation]);

  const handleRequestTemplateRemoval = useCallback(() => {
    if (!selectedTemplate) return;
    alert(`Informe este código ao administrador: ${selectedTemplate.id}`);
  }, [selectedTemplate]);

  return (
    <PageContainer>
      <Row gutter={48}>
        <PageColumn
          span={8}
          title="Modelos"
          list={[...(fbLabelTemplates || [])].map((template) => (
            <TemplateCard
              hoverable
              size="small"
              bordered={false}
              onClick={() => setSelectedTemplate(template)}
              key={template.id}
            >
              {selectedTemplate?.id === template.id ? (
                <strong>{template.name.toUpperCase()}</strong>
              ) : (
                template.name.toUpperCase()
              )}
            </TemplateCard>
          ))}
          listLoading={fbLabelTemplatesLoading}
        ></PageColumn>

        <PageColumn span={16} title="Gerar Etiqueta">
          {selectedTemplate ? (
            <>
              <Form layout="vertical">
                <Row gutter={16}>
                  <Col span={20}>
                    <Form.Item label="Cliente">
                      <Input
                        value={selectedTemplate.customerName.toUpperCase()}
                        disabled
                      />
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item label="Data">
                      <Input value={currentDate} disabled />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={5}>
                    <Form.Item label="Código">
                      <Input value={selectedTemplate.productCode} disabled />
                    </Form.Item>
                  </Col>

                  <Col span={15}>
                    <Form.Item label="Produto">
                      <Input value={selectedTemplate.productName} disabled />
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item label="Unidade">
                      <Input
                        value={selectedTemplate.productUnitAbbreviation}
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider />

                <Row gutter={16}>
                  <Col span={19}>
                    <Form.Item
                      label="Lote"
                      hasFeedback
                      validateStatus={
                        labelBatchIdIsValid === undefined
                          ? undefined
                          : labelBatchIdIsValid
                          ? "success"
                          : "error"
                      }
                      help={
                        labelBatchIdIsValid === false
                          ? "Nº do lote inválido"
                          : undefined
                      }
                    >
                      <Input
                        value={labelConfig.batchId}
                        onKeyDown={onLabelBatchIdKeyDown}
                        onBlur={() =>
                          setLabelBatchIdIsValid(handleLabelBatchIdValidation())
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={5}>
                    <Form.Item
                      label="Qtd. produção"
                      help={
                        <LabelProductQtyHelp>
                          (em {selectedTemplate.productUnitName.toLowerCase()})
                        </LabelProductQtyHelp>
                      }
                    >
                      <InputNumber
                        defaultValue={labelConfig.productQty}
                        onChange={(val) =>
                          setLabelConfig((curr) => ({
                            ...curr,
                            productQty: val,
                          }))
                        }
                        min={1}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

              <Divider />

              <Form colon={false}>
                <Row gutter={16}>
                  <Col span={6}>
                    <Form.Item label="Cópias">
                      <InputNumber
                        defaultValue={labelConfig.numOfCopies}
                        onChange={(val) =>
                          setLabelConfig((curr) => ({
                            ...curr,
                            numOfCopies: val,
                          }))
                        }
                        min={1}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={18}>
                    <Button
                      block
                      type="primary"
                      onClick={handleShowLabelPreview}
                    >
                      Imprimir
                    </Button>
                  </Col>
                </Row>
              </Form>

              <Divider />

              <LabelFooterBtnsContainer>
                <Button
                  danger
                  size="small"
                  onClick={handleRequestTemplateRemoval}
                >
                  Solicitar exclusão do modelo
                </Button>
              </LabelFooterBtnsContainer>

              <TransfLabelPreview
                visible={showLabelPreview}
                onCancel={() => setShowLabelPreview(false)}
                data={{
                  ...selectedTemplate,
                  date: currentDate,
                  batchId: labelConfig.batchId,
                  productQuantity: labelConfig.productQty,
                  copies: labelConfig.numOfCopies,
                }}
              />
            </>
          ) : (
            <Empty description="Nenhum modelo selecionado" />
          )}
        </PageColumn>
      </Row>
    </PageContainer>
  );
};

export default LabelsHomePage;
