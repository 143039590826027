import firebase from "firebase";
import { useCallback, useMemo } from "react";
import { AsyncResult, isDptId, isShopFloorDpt, Na3Dpt } from "../types";
import { makeAsyncError } from "../utils";
import useData from "./useData";
import useStateSlice from "./useStateSlice";

const useAuth = () => {
  const { user, expiresAt } = useStateSlice("auth");
  const { departments } = useData();

  const dpt = useMemo((): Na3Dpt<"shop-floor"> | undefined => {
    if (user) {
      const possibleId = user.email?.split("@")[0];
      if (!possibleId) return;
      const formattedPossibleId = possibleId.trim().toLowerCase();
      if (!isDptId(formattedPossibleId)) return;

      return departments
        .filter(isShopFloorDpt)
        .find((dpt) => dpt.id === formattedPossibleId);
    }
  }, [user, departments]);

  const signInAsync = useCallback(
    async (
      dptId: Na3Dpt<"shop-floor">["id"]
    ): Promise<AsyncResult<firebase.auth.UserCredential>> => {
      try {
        const {
          REACT_APP_FIREBASE_PASSWORD_KIT_AUTOMATICO,
          REACT_APP_FIREBASE_PASSWORD_FLEXOGRAFIA_PAPEL,
          REACT_APP_FIREBASE_PASSWORD_FLEXOGRAFIA_PLASTICO,
          REACT_APP_FIREBASE_PASSWORD_EXTRUSAO,
          REACT_APP_FIREBASE_PASSWORD_CORTE_SOLDA_LUVA,
          REACT_APP_FIREBASE_PASSWORD_CORTE_SOLDA_SACO,
          REACT_APP_FIREBASE_PASSWORD_RECICLAGEM,
          REACT_APP_FIREBASE_PASSWORD_DOBRA,
          REACT_APP_FIREBASE_PASSWORD_OFF_SET,
          REACT_APP_FIREBASE_PASSWORD_CORTE,
          REACT_APP_FIREBASE_PASSWORD_SUPER_KIT,
          REACT_APP_FIREBASE_PASSWORD_KIT_MANUAL,
          REACT_APP_FIREBASE_PASSWORD_EKOPLASTO,
        } = process.env;

        const passwordsMap: Record<
          Na3Dpt<"shop-floor">["id"],
          string | undefined
        > = {
          "kit-automatico": REACT_APP_FIREBASE_PASSWORD_KIT_AUTOMATICO,
          "flexografia-papel": REACT_APP_FIREBASE_PASSWORD_FLEXOGRAFIA_PAPEL,
          "flexografia-plastico":
            REACT_APP_FIREBASE_PASSWORD_FLEXOGRAFIA_PLASTICO,
          extrusao: REACT_APP_FIREBASE_PASSWORD_EXTRUSAO,
          "corte-solda-luva": REACT_APP_FIREBASE_PASSWORD_CORTE_SOLDA_LUVA,
          "corte-solda-saco": REACT_APP_FIREBASE_PASSWORD_CORTE_SOLDA_SACO,
          reciclagem: REACT_APP_FIREBASE_PASSWORD_RECICLAGEM,
          dobra: REACT_APP_FIREBASE_PASSWORD_DOBRA,
          "off-set": REACT_APP_FIREBASE_PASSWORD_OFF_SET,
          corte: REACT_APP_FIREBASE_PASSWORD_CORTE,
          "super-kit": REACT_APP_FIREBASE_PASSWORD_SUPER_KIT,
          "kit-manual": REACT_APP_FIREBASE_PASSWORD_KIT_MANUAL,
          ekoplasto: REACT_APP_FIREBASE_PASSWORD_EKOPLASTO,
        };

        const email = `${dptId}@novaa3-app.com.br`;
        const password = passwordsMap[dptId];

        if (!password) {
          throw new ReferenceError(
            `Nenhuma senha válida encontrada para o setor "${dptId.toUpperCase()}". Entre em contato com o administrador.`
          );
        }

        const userCreds = await firebase
          .auth()
          .signInWithEmailAndPassword(email, password);

        return { error: null, data: userCreds };
      } catch (err) {
        return {
          error: makeAsyncError(
            err,
            `Não foi possível fazer o login do setor "${dptId.toUpperCase()}".`
          ),
          data: null,
        };
      }
    },
    []
  );

  const signOutAsync = useCallback(async (): Promise<
    AsyncResult<undefined>
  > => {
    try {
      await firebase.auth().signOut();

      return { error: null, data: undefined };
    } catch (err) {
      return {
        error: makeAsyncError(err, "Não foi possível sair neste momento."),
        data: null,
      };
    }
  }, []);

  return { user, expiresAt, dpt, signInAsync, signOutAsync };
};

export default useAuth;
