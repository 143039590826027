import { Layout } from "antd";
import styled from "styled-components";

const StyledLayoutContent = styled(Layout.Content)`
  height: calc(100vh - 64px);
  overflow: hidden;
`;

const AppContent: React.FC = ({ children }) => {
  return <StyledLayoutContent>{children}</StyledLayoutContent>;
};

export default AppContent;
