import { Badge, Card, Col, Modal, Row } from "antd";
import { useCallback } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { PageContainer } from "../components";
import { PAGES } from "../constants";

const GridItemCategory = styled.div<{
  textColor?: string;
}>`
  color: ${(props) => props.textColor} !important;
`;

const HomePage: React.FC = () => {
  const history = useHistory();

  const handleGridItemClick = useCallback(
    (url: string, disabled?: boolean) => {
      if (disabled) {
        Modal.warning({ content: "Em breve" });
        return;
      }
      history.push(url);
    },
    [history]
  );

  return (
    <PageContainer paddingY={48}>
      <Row gutter={48}>
        {PAGES.map((page) => {
          if (page.homeGridConfig) {
            const { title, description, category, cover } = page.homeGridConfig;

            return (
              <Col span={6} key={page.path}>
                <Badge.Ribbon
                  text={
                    <GridItemCategory textColor={category.textColor}>
                      {category.title}
                    </GridItemCategory>
                  }
                  color={category.bgColor}
                >
                  <Card
                    hoverable
                    cover={
                      <img
                        src={cover}
                        alt={title}
                        onClick={() =>
                          handleGridItemClick(
                            page.path,
                            page.homeGridConfig?.disabled
                          )
                        }
                      />
                    }
                  >
                    <Card.Meta title={title} description={description} />
                  </Card>
                </Badge.Ribbon>
              </Col>
            );
          } else {
            return null;
          }
        })}
      </Row>
    </PageContainer>
  );
};

export default HomePage;
