import {
  DataSetDepartmentsAction,
  DataSetLoadingAction,
  DataState,
} from "../../types";

export const setDataDepartments = (
  departments: DataState["departments"]
): DataSetDepartmentsAction => ({
  type: "DATA_SET_DEPARTMENTS",
  departments,
});

export const setDataLoading = (
  loadingState: DataState["loading"]
): DataSetLoadingAction => ({
  type: "DATA_SET_LOADING",
  loading: loadingState,
});
