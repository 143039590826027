import {
  Button,
  Col,
  Divider,
  Form,
  Modal,
  Row,
  Timeline,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { FormItem } from "..";
import { refuseServiceOrderSolutionAsync } from "../../helpers";
import { useAuth } from "../../hooks";
import { ServiceOrder } from "../../types";
import { translateId } from "../../utils";
import ServiceOrderStatusBadge from "./ServiceOrderCardStatusBadge";
import ServiceOrderDetailsItem from "./ServiceOrderDetailsItem";

type ServiceOrderDetailsModalProps = {
  data: ServiceOrder;
  visible: boolean;
  handleCancel: () => void;
  handleAcceptSolution: () => void;
  handleRefuseSolution: (
    payload: Parameters<typeof refuseServiceOrderSolutionAsync>[1]
  ) => void;
};

const ServiceOrderDetailsModal: React.FC<ServiceOrderDetailsModalProps> = ({
  visible,
  handleCancel,
  handleAcceptSolution,
  handleRefuseSolution,
  data: {
    id,
    description,
    status,
    dpt,
    machine,
    team,
    maintenanceType,
    cause,
    interruptions,
    solution,
    events,
  },
}) => {
  const auth = useAuth();

  const [showRefusalModal, setShowRefusalModal] = useState(false);
  const [refusalReason, setRefusalReason] = useState<string>();
  const [refusalReasonError, setRefusalReasonError] = useState<string>();

  const refusalReasonIsValid = useCallback(() => {
    if (!refusalReason) return false;
    if (refusalReason.trim().length < 20) return false;
    return true;
  }, [refusalReason]);

  const handleRefusalReasonChanged = useCallback((val: string) => {
    setRefusalReasonError(undefined);
    setRefusalReason(val);
  }, []);

  const handleRefusalReasonValidation = useCallback(() => {
    const isValid = refusalReasonIsValid();
    if (!isValid) {
      setRefusalReasonError("Curta demais. Mínimo 20 caracteres.");
    }
    return isValid;
  }, [refusalReasonIsValid]);

  return (
    <>
      <Modal
        centered
        visible={visible}
        title={`OS #${id} — ${description}`}
        onCancel={handleCancel}
        footer={null}
        width="70vw"
      >
        {status === "solved" && (
          <>
            <Row gutter={8}>
              <Col span={16}>
                <Button block type="primary" onClick={handleAcceptSolution}>
                  Aceitar solução
                </Button>
              </Col>
              <Col span={8}>
                <Button block danger onClick={() => setShowRefusalModal(true)}>
                  Recusar solução
                </Button>
              </Col>
            </Row>

            <Divider />
          </>
        )}

        <Row gutter={16}>
          <Col span={12}>
            <ServiceOrderDetailsItem label="Status">
              <ServiceOrderStatusBadge status={status} />
            </ServiceOrderDetailsItem>

            <Divider />

            <ServiceOrderDetailsItem label="Setor">
              {dpt}
            </ServiceOrderDetailsItem>
            <ServiceOrderDetailsItem label="Máquina">
              {(auth.dpt?.machines &&
                auth.dpt.machines[machine]?.name.toUpperCase()) ||
                machine}
            </ServiceOrderDetailsItem>

            <Divider />

            <ServiceOrderDetailsItem label="Equipe responsável">
              {translateId(team)}
            </ServiceOrderDetailsItem>
            <ServiceOrderDetailsItem label="Tipo de manutenção">
              {translateId(maintenanceType)}
            </ServiceOrderDetailsItem>
            <ServiceOrderDetailsItem label="Tipo de causa">
              {translateId(cause)}
            </ServiceOrderDetailsItem>

            <Divider />

            <ServiceOrderDetailsItem label="Parou máquina?">
              {interruptions.equipment ? "SIM" : "NÃO"}
            </ServiceOrderDetailsItem>
            <ServiceOrderDetailsItem label="Parou linha?">
              {interruptions.line ? "SIM" : "NÃO"}
            </ServiceOrderDetailsItem>
            <ServiceOrderDetailsItem label="Parou produção?">
              {interruptions.production ? "SIM" : "NÃO"}
            </ServiceOrderDetailsItem>
          </Col>

          <Col span={12}>
            <ServiceOrderDetailsItem label="Solução atribuída">
              {solution ? solution : <em>Nenhuma solução disponível</em>}
            </ServiceOrderDetailsItem>

            <Divider />

            <ServiceOrderDetailsItem stacked label="Histórico">
              <Timeline mode="left">
                {events
                  .filter((ev) => ev.type !== "solutionStepAdded")
                  .map((ev) => {
                    const TooltipContent: React.FC = () => {
                      if (!ev.payload) return null;

                      if (ev.type === "ticketConfirmed") {
                        return (
                          <>
                            {ev.payload.priority && (
                              <div>
                                <strong>Prioridade: </strong>
                                {translateId(ev.payload.priority)}
                              </div>
                            )}
                            {ev.payload.assignedMaintainer && (
                              <div>
                                <strong>Responsável: </strong>
                                {ev.payload.assignedMaintainer}
                              </div>
                            )}
                          </>
                        );
                      }

                      switch (Object.keys(ev.payload)[0]) {
                        case "priority":
                          return (
                            <>
                              <strong>Nova prioridade: </strong>
                              {translateId(ev.payload.priority)}
                            </>
                          );
                        case "solutionStep":
                          return (
                            <>
                              {ev.payload.solutionStep?.content}
                              {ev.payload.solutionStep?.who && (
                                <em> ({ev.payload.solutionStep?.who})</em>
                              )}
                            </>
                          );
                        case "solution":
                          return (
                            <>
                              {typeof ev.payload.solution === "string"
                                ? ev.payload.solution
                                : ev.payload.solution?.content}
                              {ev.payload.solution &&
                                !(typeof ev.payload.solution === "string") &&
                                ev.payload.solution.who && (
                                  <em> ({ev.payload.solution.who})</em>
                                )}
                            </>
                          );
                        default:
                          return null;
                      }
                    };

                    return (
                      <Timeline.Item
                        label={dayjs(ev.timestamp).format("DD/MM/YYYY hh:mm")}
                        color={
                          ev.type === "solutionAccepted" ||
                          ev.type === "ticketClosed"
                            ? "green"
                            : ev.type === "solutionRefused" ||
                              ev.type === "ticketReopened"
                            ? "red"
                            : "blue"
                        }
                        key={ev.id}
                      >
                        <Tooltip
                          title={<TooltipContent />}
                          visible={ev.payload ? undefined : false}
                          placement="topLeft"
                        >
                          {translateId(ev.type)}
                        </Tooltip>
                      </Timeline.Item>
                    );
                  })}
              </Timeline>
            </ServiceOrderDetailsItem>
          </Col>
        </Row>
      </Modal>

      <Modal
        visible={showRefusalModal}
        onCancel={() => setShowRefusalModal(false)}
        title="Recusar solução"
        okText="Recusar solução"
        okButtonProps={{ disabled: !refusalReasonIsValid(), danger: true }}
        cancelText="Voltar"
        onOk={() => {
          if (refusalReason && handleRefusalReasonValidation()) {
            setShowRefusalModal(false);
            handleRefuseSolution({ refusalReason });
          }
        }}
      >
        <Form layout="vertical">
          <FormItem
            as="textArea"
            rows={3}
            label="Motivo da recusa"
            onValueChange={(val) => handleRefusalReasonChanged(val)}
            error={refusalReasonError}
            maxLength={200}
            autoFocus
          />
        </Form>
      </Modal>
    </>
  );
};

export default ServiceOrderDetailsModal;
