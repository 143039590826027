import { cyan } from "@ant-design/colors";
import {
  HomePage,
  LabelsHomePage,
  ServiceOrdersHomePage,
  SignInPage,
  TransfHomePage,
} from "../pages";

export type Page = {
  path: `/${string}`;
  Component: React.ComponentType<any>;
  title?: string;
  isPublic?: boolean;
  homeGridConfig?: {
    title: string;
    description: string;
    cover: string;
    category: {
      title: string;
      bgColor?: string;
      textColor?: "white" | "black";
    };
    disabled?: boolean;
  };
};

export const PAGES: Page[] = [
  {
    path: "/manutencao/os",
    Component: ServiceOrdersHomePage,
    title: "OS",
    homeGridConfig: {
      title: "Ordens de Serviço",
      description: "Abrir/Consultar Ordens de Serviço",
      cover: "/images/home-grid-maintenance.png",
      category: { title: "Manutenção" },
    },
  },
  {
    path: "/docs/transf",
    Component: TransfHomePage,
    title: "Transferências",
    homeGridConfig: {
      title: "Transferências",
      description: "Abrir/Consultar Transferências",
      cover: "/images/home-grid-transf.png",
      category: { title: "Documentos", bgColor: cyan.primary },
      disabled: false,
    },
  },
  {
    path: "/etiquetas",
    Component: LabelsHomePage,
    title: "Etiquetas",
    homeGridConfig: {
      title: "Etiquetas",
      description: "Gerar/Imprimir Etiquetas",
      cover: "/images/home-grid-transf.png",
      category: { title: "Etiquetas", bgColor: cyan.primary },
      disabled: false,
    },
  },
  {
    path: "/auth/entrar",
    Component: SignInPage,
    title: "Entrar",
    isPublic: true,
  },
  {
    path: "/",
    Component: HomePage,
  },
];
