import { ConfigProvider as AntdConfigProvider } from "antd";
import "antd/dist/antd.css";
import ptBR from "antd/lib/locale/pt_BR";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import firebase from "firebase";
import React from "react";
import ReactDOM from "react-dom";
import { Provider as StoreProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import store from "./store";

dayjs.extend(duration);
dayjs.extend(timezone);
dayjs.extend(utc);

firebase.initializeApp({
  apiKey: "AIzaSyAynKF5joA-_wpax9jzatonSZgxSE-MaRQ",
  authDomain: "nova-a3-ind.firebaseapp.com",
  projectId: "nova-a3-ind",
  storageBucket: "nova-a3-ind.appspot.com",
  messagingSenderId: "810900069450",
  appId: "1:810900069450:web:b3e69b0290fbadaac59ab3",
  measurementId: "G-FE66ENBT66",
});

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <AntdConfigProvider locale={ptBR}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AntdConfigProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
