import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  message,
  Modal,
  notification,
  Row,
} from "antd";
import firebase from "firebase";
import { useCallback, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Redirect } from "react-router-dom";
import {
  FormItem,
  PageColumn,
  PageContainer,
  ServiceOrderCard,
} from "../../components";
import {
  collectionId,
  createServiceOrderAsync,
  getNextServiceOrderIdAsync,
  sortServiceOrders,
} from "../../helpers";
import { useAuth, useData } from "../../hooks";
import { ServiceOrder } from "../../types";

const ServiceOrdersHomePage: React.FC = () => {
  const { dpt } = useAuth();
  const { globalLoad } = useData();
  const [fbServiceOrders, fbServiceOrdersLoading, fbServiceOrdersError] =
    useCollectionData<ServiceOrder, "id", "ref">(
      firebase
        .firestore()
        .collection(collectionId("tickets"))
        .where("username", "==", dpt?.id || ""),
      { idField: "id", refField: "ref" }
    );
  const [newServiceOrderValues, setNewServiceOrderValues] = useState<{
    machine: string | undefined;
    issue: string | undefined;
    didStopMachine: boolean;
    didStopLine: boolean;
    didStopProduction: boolean;
    team: string | undefined;
    maintenanceType: string | undefined;
    cause: string | undefined;
    additionalInfo: string | undefined;
  }>({
    machine: undefined,
    issue: undefined,
    didStopMachine: false,
    didStopLine: false,
    didStopProduction: false,
    team: undefined,
    maintenanceType: undefined,
    cause: undefined,
    additionalInfo: undefined,
  });
  const [newServiceOrderErrors, setNewServiceOrderErrors] = useState<
    Partial<Record<keyof typeof newServiceOrderValues, string>>
  >({});

  const sortedServiceOrders = sortServiceOrders(fbServiceOrders);

  const handleFormChange = useCallback(
    <T extends keyof typeof newServiceOrderValues>(
      key: T,
      value: typeof newServiceOrderValues[T]
    ) => {
      setNewServiceOrderErrors((curr) => ({ ...curr, [key]: "" }));
      setNewServiceOrderValues((curr) => ({ ...curr, [key]: value }));
    },
    []
  );

  const handleNewServiceOrderSubmit = useCallback(async () => {
    if (!dpt) {
      notification.error({
        message: "Não foi possível carregar o setor",
        description: "Por favor, refaça o login.",
      });
      return;
    }

    const {
      machine,
      issue,
      team,
      maintenanceType,
      cause,
      didStopMachine,
      didStopLine,
      didStopProduction,
      additionalInfo,
    } = newServiceOrderValues;

    const errors: Partial<typeof newServiceOrderErrors> = {};

    if (!machine) errors.machine = "Selecione uma máquina.";
    if (!issue) errors.issue = "Defina um problema.";
    if (!team) errors.team = "Defina a equipe responsável.";
    if (!maintenanceType)
      errors.maintenanceType = "Defina o tipo de manutenção.";
    if (!cause) errors.cause = "Defina o tipo de causa.";

    if (Object.keys(errors).length) {
      setNewServiceOrderErrors(errors);
      message.error("Corrija os campos com erro.");
      return;
    }

    const idRes = await globalLoad(getNextServiceOrderIdAsync);

    if (idRes.error) {
      notification.error({
        message: "Não foi possível abrir a OS",
        description: idRes.error.message,
      });
      return;
    }

    const id = idRes.data;

    const confirmModal = Modal.confirm({
      content: `Confirma a abertura da OS #${id}?`,
      onOk: async () => {
        confirmModal.destroy();

        const res = await globalLoad(() =>
          createServiceOrderAsync(id, {
            dpt: dpt.name.toUpperCase(),
            username: dpt.id,
            machine: machine || "",
            description: issue || "",
            interruptions: {
              equipment: didStopMachine,
              line: didStopLine,
              production: didStopProduction,
            },
            team: team || "",
            maintenanceType: maintenanceType || "",
            cause: cause || "",
            additionalInfo: additionalInfo || null,
          })
        );

        if (res.error) {
          notification.error({
            message: "Não foi possível abrir a OS",
            description: res.error.message,
          });
        } else {
          notification.success({
            message: `OS #${id}`,
            description: "OS aberta com sucesso!",
          });

          setNewServiceOrderValues({
            machine: undefined,
            issue: "",
            didStopMachine: false,
            didStopLine: false,
            didStopProduction: false,
            team: undefined,
            maintenanceType: undefined,
            cause: undefined,
            additionalInfo: undefined,
          });
          setNewServiceOrderErrors({});
        }
      },
    });
  }, [dpt, newServiceOrderValues, globalLoad]);

  return (
    <>
      {dpt ? (
        <PageContainer>
          <Row gutter={48}>
            <PageColumn
              span={8}
              title="Suas OS"
              list={sortedServiceOrders.map((so, idx) => (
                <ServiceOrderCard
                  data={so}
                  key={so.id}
                  noMarginBottom={idx === sortedServiceOrders.length - 1}
                />
              ))}
              listLoading={fbServiceOrdersLoading}
            />

            <PageColumn span={16} title="Abrir OS">
              <Form layout="vertical">
                <FormItem
                  label="Setor"
                  defaultValue={dpt.displayName.toUpperCase()}
                  disabled
                />

                <FormItem
                  as="select"
                  label="Máquina"
                  placeholder="Toque para selecionar a máquina"
                  options={Object.values(dpt.machines)
                    .sort((a, b) => a.number - b.number)
                    .map((machine) => ({
                      value: machine.id,
                      label: machine.name.toUpperCase(),
                    }))}
                  onValueChange={(value) => handleFormChange("machine", value)}
                  error={newServiceOrderErrors.machine}
                />

                <FormItem
                  as="autoComplete"
                  label="Problema"
                  placeholder="Toque para selecionar ou descrever o problema"
                  options={[
                    ...(newServiceOrderValues.machine
                      ? dpt.machines[newServiceOrderValues.machine].issues
                      : Object.values(dpt.machines).reduce((acc, machine) => {
                          machine.issues.forEach((issue) => {
                            if (!acc.find((i) => i === issue)) {
                              acc.push(issue);
                            }
                          });
                          return acc;
                        }, [] as string[])),
                  ]
                    .sort((a, b) => a.localeCompare(b))
                    .map((issue) => ({
                      value: issue,
                      label: issue.toUpperCase(),
                    }))}
                  onValueChange={(value) => handleFormChange("issue", value)}
                  error={newServiceOrderErrors.issue}
                />

                <Divider />

                <Form.Item label="Interrupções" required>
                  <Row>
                    <Col span={8}>
                      <Checkbox
                        checked={newServiceOrderValues.didStopMachine}
                        onChange={(ev) =>
                          handleFormChange("didStopMachine", ev.target.checked)
                        }
                      >
                        Parou máquina?
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox
                        checked={newServiceOrderValues.didStopLine}
                        onChange={(ev) =>
                          handleFormChange("didStopLine", ev.target.checked)
                        }
                      >
                        Parou linha?
                      </Checkbox>
                    </Col>
                    <Col span={8}>
                      <Checkbox
                        checked={newServiceOrderValues.didStopProduction}
                        onChange={(ev) =>
                          handleFormChange(
                            "didStopProduction",
                            ev.target.checked
                          )
                        }
                      >
                        Parou produção?
                      </Checkbox>
                    </Col>
                  </Row>
                </Form.Item>

                <Divider />

                <FormItem
                  as="radioButtons"
                  label="Tipo de causa"
                  options={[
                    { value: "mecanica", label: "Mecânica" },
                    { value: "eletrica", label: "Elétrica" },
                    { value: "predial", label: "Predial" },
                  ]}
                  onValueChange={(value) => handleFormChange("team", value)}
                  error={newServiceOrderErrors.team}
                />

                <FormItem
                  as="radioButtons"
                  label="Tipo de causa"
                  options={[
                    { value: "preventiva", label: "Preventiva" },
                    { value: "corretiva", label: "Corretiva" },
                    { value: "preditiva", label: "Preditiva" },
                  ]}
                  onValueChange={(value) =>
                    handleFormChange("maintenanceType", value)
                  }
                  error={newServiceOrderErrors.maintenanceType}
                />

                <FormItem
                  as="radioButtons"
                  label="Tipo de causa"
                  options={[
                    { value: "mecanica", label: "Mecânica" },
                    { value: "eletrica", label: "Elétrica" },
                    { value: "machineAdjustment", label: "Ajuste de máquina" },
                  ]}
                  onValueChange={(value) => handleFormChange("cause", value)}
                  error={newServiceOrderErrors.cause}
                />

                <Divider />

                <FormItem
                  notRequired
                  as="textArea"
                  label="Informações adicionais"
                  rows={3}
                  placeholder="Informações adicionais"
                  onValueChange={(value) =>
                    handleFormChange("additionalInfo", value)
                  }
                  error={newServiceOrderErrors.additionalInfo}
                />

                <Divider />

                <Button
                  block
                  type="primary"
                  onClick={handleNewServiceOrderSubmit}
                >
                  Abrir OS
                </Button>
              </Form>
            </PageColumn>
          </Row>
        </PageContainer>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default ServiceOrdersHomePage;
