import { AuthSetUserAction, AuthState } from "../../types";

export const setAuthUser = ({
  user,
  expiresAt,
}: Pick<AuthState, "user" | "expiresAt">): AuthSetUserAction => ({
  type: "AUTH_SET_USER",
  user,
  expiresAt: expiresAt,
});
