import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Promisable, PromiseValue } from "type-fest";
import { setDataLoading } from "../store/actions";
import { AsyncResult } from "../types";
import { makeAsyncError } from "../utils";
import useStateSlice from "./useStateSlice";

const useData = () => {
  const data = useStateSlice("data");
  const dispatch = useDispatch();

  const globalLoad = useCallback(
    async <T extends () => Promisable<any>>(
      cb: T
    ): Promise<AsyncResult<PromiseValue<ReturnType<T>>>> => {
      try {
        dispatch(setDataLoading(true));

        const data = await cb();

        dispatch(setDataLoading(false));

        return { error: null, data };
      } catch (err) {
        return {
          error: makeAsyncError(err),
          data: null,
        };
      }
    },
    [dispatch]
  );

  return {
    ...data,
    globalLoad,
  };
};

export default useData;
