import { Col, Divider } from "antd";
import styled from "styled-components";
import Empty from "../Empty";
import Spinner from "../Spinner";

type PageColumnProps = {
  span: React.ComponentProps<typeof Col>["span"];
  title: string;
  list?: React.ReactNode[];
  listLoading?: boolean;
};

const Container = styled(Col)`
  height: calc(100vh - 64px + 15px);
  overflow: scroll;
  padding: 30px 0;
`;

const ListInfo = styled.div`
  text-align: center;
`;

const PageColumn: React.FC<PageColumnProps> = ({
  span,
  title,
  list,
  listLoading,
  children,
}) => {
  return (
    <Container span={span}>
      <Divider orientation="left">{title}</Divider>

      {list ? (
        listLoading ? (
          <ListInfo>
            <Spinner />
          </ListInfo>
        ) : list.length === 0 ? (
          <ListInfo>
            <Empty />
          </ListInfo>
        ) : (
          list
        )
      ) : (
        children
      )}
    </Container>
  );
};

export default PageColumn;
