import { Tag } from "antd";
import styled from "styled-components";
import { ServiceOrder } from "../../types";

type ServiceOrderCardPriorityTagProps = {
  priority: ServiceOrder["priority"];
};

const Container = styled(Tag)`
  margin-right: 0;
`;

const ServiceOrderCardPriorityTag: React.FC<ServiceOrderCardPriorityTagProps> =
  ({ priority }) => {
    const config: { color?: string; text?: string } = {};

    switch (priority) {
      case "high":
        config.color = "success";
        config.text = "ALTA";
        break;
      case "medium":
        config.color = "warning";
        config.text = "MÉDIA";
        break;
      case "low":
        config.color = "error";
        config.text = "BAIXA";
        break;
      default:
        break;
    }

    return priority ? (
      <Container color={config.color}>
        {config.text && (
          <small>
            <strong>{config.text}</strong>
          </small>
        )}
      </Container>
    ) : null;
  };

export default ServiceOrderCardPriorityTag;
