import { detect as detectBrowser } from "detect-browser";

export const getDevice = (): {
  name: string;
  model: string;
  os: { name: string; version: string };
} => {
  const browser = detectBrowser();
  return {
    name: "App Fábrica",
    model: browser?.name || "App Fábrica",
    os: { name: browser?.os || "—", version: browser?.version || "0.0.0" },
  };
};
