import { Col, Row } from "antd";
import styled from "styled-components";

type ServiceOrderDetailsItemProps = {
  label: string;
  stacked?: boolean;
};

const ItemContainer = styled(Row)`
  margin-top: 6px;
  margin-bottom: 6px;
`;

const Label = styled.strong<{ stacked?: boolean }>`
  display: ${(props) => props.stacked && "block"};
  margin-bottom: ${(props) => props.stacked && "18px"};
`;

const ServiceOrderDetailsItem: React.FC<ServiceOrderDetailsItemProps> = ({
  label,
  stacked,
  children,
}) => {
  return (
    <ItemContainer>
      <Col span={stacked ? 24 : 12}>
        <Label stacked={stacked}>{label}</Label>
      </Col>

      <Col span={stacked ? 24 : 12}>{children}</Col>
    </ItemContainer>
  );
};

export default ServiceOrderDetailsItem;
