import {
  AutoComplete,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
} from "antd";
import { useCallback, useEffect, useState } from "react";

type FormItemProps<T extends string | number> = {
  label: string;
  defaultValue?: T;
  onValueChange?: (value: T) => void;
  error?: string;
  validators?: {}[];
  placeholder?: string;
  disabled?: boolean;
  notRequired?: boolean;
};

type FormItemInputProps = FormItemProps<string> & {
  as?: "input";
  autoFocus?: boolean;
};

type FormItemTextAreaProps = FormItemProps<string> & {
  as: "textArea";
  rows: number;
  maxLength?: number;
  autoFocus?: boolean;
};

type FormItemSelectProps = FormItemProps<string> & {
  as: "select";
  options: { value: string; label: React.ReactNode }[];
  autoFocus?: boolean;
};

type FormItemAutoCompleteProps = FormItemProps<string> & {
  as: "autoComplete";
  options: { value: string; label: string }[];
  autoFocus?: boolean;
};

type FormItemRadioButtonsProps = FormItemProps<string> & {
  as: "radioButtons";
  options: { value: string; label: React.ReactNode }[];
};

function FormItem(props: FormItemInputProps): ReturnType<React.FC>;
function FormItem(props: FormItemTextAreaProps): ReturnType<React.FC>;
function FormItem(props: FormItemSelectProps): ReturnType<React.FC>;
function FormItem(props: FormItemAutoCompleteProps): ReturnType<React.FC>;
function FormItem(props: FormItemRadioButtonsProps): ReturnType<React.FC>;
function FormItem(
  props:
    | FormItemInputProps
    | FormItemTextAreaProps
    | FormItemSelectProps
    | FormItemAutoCompleteProps
    | FormItemRadioButtonsProps
): ReturnType<React.FC> {
  const { onValueChange, notRequired } = props;

  const [value, setValue] = useState(props.defaultValue);
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState<string>();

  const handleChange = useCallback(
    (
      eventOrValue:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | RadioChangeEvent
        | string
    ) => {
      const updatedValue =
        typeof eventOrValue === "string"
          ? eventOrValue
          : eventOrValue.target.value;
      setValue(updatedValue);
      if (onValueChange) onValueChange(updatedValue);
    },
    [onValueChange]
  );

  const handleBlur = useCallback(() => {
    if (!touched) setTouched(true);
  }, [touched]);

  const handleAutoCompleteFilterOption: Exclude<
    React.ComponentProps<typeof AutoComplete>["filterOption"],
    boolean | undefined
  > = useCallback((input, option) => {
    return option!.value.toUpperCase().indexOf(input.toUpperCase()) !== -1;
  }, []);

  useEffect(() => {
    if (touched) {
      if (!value && !notRequired) {
        setError("Campo obrigatório");
        return;
      } else {
        setError(undefined);
      }
    }
  }, [value, touched, notRequired]);

  return (
    <>
      <Form.Item
        label={props.label}
        required={!props.disabled && !notRequired}
        hasFeedback
        validateStatus={
          props.error
            ? "error"
            : touched
            ? error
              ? "error"
              : "success"
            : undefined
        }
        help={props.error ? props.error : error}
      >
        {props.as === "select" ? (
          <Select
            value={value}
            onSelect={handleChange}
            onBlur={handleBlur}
            placeholder={props.placeholder || "Toque para preencher"}
            disabled={props.disabled}
            autoFocus={props.autoFocus}
          >
            {props.options.map((option) => (
              <Select.Option value={option.value} key={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        ) : props.as === "radioButtons" ? (
          <Radio.Group
            value={value}
            onChange={handleChange}
            disabled={props.disabled}
            style={{ width: "100%" }}
          >
            {props.options.map((option) => (
              <Radio.Button
                value={option.value}
                key={option.value}
                style={{ width: `${100 / props.options.length}%` }}
              >
                {option.label}
              </Radio.Button>
            ))}
          </Radio.Group>
        ) : props.as === "autoComplete" ? (
          <AutoComplete
            options={props.options}
            filterOption={handleAutoCompleteFilterOption}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={props.placeholder || "Toque para preencher"}
            disabled={props.disabled}
            autoFocus={props.autoFocus}
          />
        ) : props.as === "textArea" ? (
          <Input.TextArea
            rows={props.rows}
            maxLength={props.maxLength}
            showCount={!!props.maxLength}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={props.placeholder || "Toque para preencher"}
            disabled={props.disabled}
            autoFocus={props.autoFocus}
          />
        ) : (
          <Input
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={props.placeholder || "Toque para preencher"}
            disabled={props.disabled}
            autoFocus={props.autoFocus}
          />
        )}
      </Form.Item>
    </>
  );
}

export default FormItem;
