import { FixedLengthArray } from "type-fest";

export const getShifts = (): FixedLengthArray<
  { id: string; startTime: string; endTime: string },
  7
> => {
  return [
    { id: "A", startTime: "05:20", endTime: "13:40" },
    { id: "B", startTime: "13:40", endTime: "22:00" },
    { id: "C", startTime: "22:00", endTime: "05:20" },
    { id: "D", startTime: "22:28", endTime: "07:00" },
    { id: "E", startTime: "07:00", endTime: "15:20" },
    { id: "F", startTime: "08:00", endTime: "16:20" },
    { id: "G", startTime: "08:00", endTime: "16:48" },
  ];
};
