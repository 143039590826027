import { Badge, Card, Divider, Modal, notification, Typography } from "antd";
import { useCallback, useState } from "react";
import {
  IoCheckmarkDoneOutline,
  IoCreateOutline,
  IoLockClosedOutline,
  IoThumbsUpOutline,
} from "react-icons/io5";
import styled from "styled-components";
import {
  acceptServiceOrderSolutionAsync,
  refuseServiceOrderSolutionAsync,
} from "../../helpers";
import { useData } from "../../hooks";
import { ServiceOrder } from "../../types";
import ServiceOrderPriorityTag from "./ServiceOrderCardPriorityTag";
import ServiceOrderStatusBadge from "./ServiceOrderCardStatusBadge";
import ServiceOrderStep from "./ServiceOrderCardStep";
import ServiceOrderDetailsModal from "./ServiceOrderDetailsModal";

type ServiceOrderCardProps = {
  data: ServiceOrder;
  noMarginBottom?: boolean;
};

const StyledCard = styled(Card)<{ noMarginBottom?: boolean }>`
  border-radius: 6px;
  margin-bottom: ${(props) => (props.noMarginBottom ? "0px" : "6px")};
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
`;

const ServiceOrderId = styled.small`
  margin-right: 8px;
`;

const CardTitle = styled(Typography.Title)`
  display: inline;
  margin: 0;
`;

const CardDivider = styled(Divider)`
  margin-top: 6px;
  margin-bottom: 9px;
`;

const StepsContainer = styled.div`
  display: flex;
`;

const ServiceOrderCard: React.FC<ServiceOrderCardProps> = ({
  data,
  noMarginBottom,
}) => {
  const {
    id,
    description,
    status,
    priority,
    createdAt,
    acceptedAt,
    solvedAt,
    closedAt,
  } = data;

  const [renderDetailsModal, setRenderDetailsModal] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const { globalLoad } = useData();

  const onAcceptSolution = useCallback(() => {
    if (status !== "solved") return;

    const confirmModal = Modal.confirm({
      content: (
        <>
          <div>Confirma o encerramento da OS #{id}?</div>
          <br />
          <em>Atenção! Esta ação é permanente.</em>
        </>
      ),
      okText: "Encerrar OS",
      cancelText: "Não, voltar",
      onOk: async () => {
        confirmModal.destroy();

        setShowDetails(false);

        const res = await globalLoad(() =>
          acceptServiceOrderSolutionAsync(data)
        );

        if (res.error) {
          notification.error({
            message: "Não foi possível encerrar a OS",
            description: res.error.message,
          });
        } else {
          notification.success({
            message: `OS #${id}`,
            description: "OS encerrada com sucesso!",
          });
        }
      },
    });
  }, [id, status, data, globalLoad]);

  const onRefuseSolution = useCallback(
    (payload: Parameters<typeof refuseServiceOrderSolutionAsync>[1]) => {
      if (status !== "solved") return;

      const confirmModal = Modal.confirm({
        content: `Confirma a reabertura da OS #${id}?`,
        okText: "Recusar solução e reabrir OS",
        cancelText: "Não, voltar",
        okButtonProps: { danger: true },
        onOk: async () => {
          confirmModal.destroy();

          setShowDetails(false);

          const res = await globalLoad(() =>
            refuseServiceOrderSolutionAsync(data, payload)
          );

          if (res.error) {
            notification.error({
              message: "Não foi possível reabrir a OS",
              description: res.error.message,
            });
          } else {
            notification.success({
              message: `OS #${id}`,
              description: "OS reaberta com sucesso!",
            });
          }
        },
      });
    },
    [id, status, data, globalLoad]
  );

  const Card: React.FC = () => (
    <StyledCard
      hoverable
      bodyStyle={{ paddingTop: 12, paddingBottom: 20 }}
      onClick={() => setShowDetails(true)}
      onMouseOver={() => setRenderDetailsModal(true)}
      noMarginBottom={noMarginBottom}
    >
      <CardHeader>
        <ServiceOrderStatusBadge status={status} />

        {status === "solving" && (
          <ServiceOrderPriorityTag priority={priority} />
        )}
      </CardHeader>

      <ServiceOrderId>
        <Typography.Text italic type="secondary">
          #{id}
        </Typography.Text>
      </ServiceOrderId>

      <CardTitle level={5}>{description}</CardTitle>

      <CardDivider />

      <StepsContainer>
        <ServiceOrderStep icon={<IoCreateOutline />} timestamp={createdAt} />
        <ServiceOrderStep icon={<IoThumbsUpOutline />} timestamp={acceptedAt} />
        <ServiceOrderStep
          icon={<IoCheckmarkDoneOutline />}
          timestamp={solvedAt}
        />
        <ServiceOrderStep icon={<IoLockClosedOutline />} timestamp={closedAt} />
      </StepsContainer>
    </StyledCard>
  );

  return (
    <>
      {status === "solved" ? (
        <Badge.Ribbon text="Ação necessária" color="red">
          <Card />
        </Badge.Ribbon>
      ) : (
        <Card />
      )}

      {renderDetailsModal && (
        <ServiceOrderDetailsModal
          visible={showDetails}
          handleCancel={() => setShowDetails(false)}
          handleAcceptSolution={onAcceptSolution}
          handleRefuseSolution={onRefuseSolution}
          data={data}
        />
      )}
    </>
  );
};

export default ServiceOrderCard;
