import { AuthAction, AuthState } from "../../types";

const initialState: AuthState = {
  user: undefined,
  expiresAt: null,
};

const reducer = (state = initialState, action: AuthAction): AuthState => {
  switch (action.type) {
    case "AUTH_SET_USER":
      return { ...state, user: action.user, expiresAt: action.expiresAt };
    default:
      return state;
  }
};

export default reducer;
