import { Button, Layout, message, notification, Typography } from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAuth, useData } from "../../hooks";

const StyledLayoutHeader = styled(Layout.Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
`;

const AppTitleLink = styled(Link)`
  color: inherit;
`;

const AppTitle = styled(Typography.Title)`
  color: inherit !important;
  margin: 0 !important;
`;

const HeaderInfo = styled(Typography.Text)`
  color: inherit !important;
  margin-right: 32px;
`;

const AppHeader: React.FC = () => {
  const { expiresAt, dpt, signOutAsync } = useAuth();
  const { globalLoad } = useData();
  const [expiresIn, setExpiresIn] = useState<plugin.Duration>();

  const onSignOut = useCallback(() => {
    globalLoad(async () => {
      const signOutRes = await signOutAsync();

      if (signOutRes.error) {
        notification.error({
          message: "Erro ao sair",
          description: signOutRes.error.message,
        });
      } else {
        message.success("Você saiu");
      }
    });
  }, [globalLoad, signOutAsync]);

  useEffect(() => {
    if (expiresIn) {
      const timer = setTimeout(() => {
        setExpiresIn(expiresIn.subtract(1, "second"));
      }, 1000);
      return () => clearInterval(timer);
    } else if (expiresAt) {
      setExpiresIn(dayjs.duration(dayjs(expiresAt).clone().diff(dayjs())));
    }
  }, [expiresIn, expiresAt]);

  return (
    <StyledLayoutHeader>
      <AppTitleLink to="/">
        <AppTitle level={3}>Nova A3</AppTitle>
      </AppTitleLink>

      <div>
        {dpt && (
          <>
            <HeaderInfo italic>
              Logado como: {dpt.displayName.toUpperCase()}
            </HeaderInfo>

            {expiresIn && (
              <HeaderInfo code>{expiresIn.format("mm:ss")}</HeaderInfo>
            )}

            <Button danger type="primary" onClick={onSignOut}>
              Sair
            </Button>
          </>
        )}
      </div>
    </StyledLayoutHeader>
  );
};

export default AppHeader;
