import { Button, Form, message, notification, Select, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { PageColumn, PageContainer } from "../../components";
import { useAuth, useData } from "../../hooks";
import { isShopFloorDpt, Na3Dpt } from "../../types";

const Instructions = styled(Typography.Paragraph)`
  margin-bottom: 48px !important;
`;

const SignInPage: React.FC = () => {
  const { departments, globalLoad } = useData();
  const { user, signInAsync } = useAuth();
  const [authDpt, setAuthDpt] = useState<Na3Dpt<"shop-floor">["id"]>();
  const [signInError, setSignInError] = useState("");
  const history = useHistory();

  const onSignIn = useCallback(() => {
    if (!authDpt) {
      return;
    }

    globalLoad(async () => {
      const signInRes = await signInAsync(authDpt);

      if (signInRes.error) {
        setSignInError(signInRes.error.message);
        notification.error({
          message: "Erro ao entrar",
          description: signInRes.error.message,
        });
      } else {
        setSignInError("");
        message.success("Autenticado!");
      }
    });
  }, [authDpt, globalLoad, signInAsync]);

  useEffect(() => {
    if (user) {
      history.replace("/");
    }
  }, [user, history]);

  return (
    <PageContainer>
      <PageColumn span={24} title="Entrar">
        <Instructions italic>Selecione seu setor para continuar.</Instructions>

        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          labelAlign="left"
        >
          <Form.Item
            label="Setor"
            colon={false}
            hasFeedback
            validateStatus={
              signInError ? "error" : authDpt ? "success" : undefined
            }
            help={signInError || undefined}
          >
            <Select
              placeholder="Toque para selecionar seu setor"
              value={authDpt}
              onSelect={(value) => {
                setSignInError("");
                setAuthDpt(value);
              }}
            >
              {departments.filter(isShopFloorDpt).map((dpt) => (
                <Select.Option value={dpt.id} key={dpt.id}>
                  {dpt.displayName.toUpperCase()}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
            <Button block type="primary" onClick={onSignIn} disabled={!authDpt}>
              Entrar
            </Button>
          </Form.Item>
        </Form>
      </PageColumn>
    </PageContainer>
  );
};

export default SignInPage;
