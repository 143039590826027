import { AsyncError, isFirebaseError } from "../types";

export const makeAsyncError = (
  err: unknown,
  unknownErrorMsg?: `${string}.`
): AsyncError => {
  if (err instanceof Error || isFirebaseError(err)) {
    return err;
  } else {
    return {
      name: "UnknownError",
      message: `${
        unknownErrorMsg ? unknownErrorMsg + " " : ""
      }Um erro desconhecido ocorreu. Entre em contato com o administrador ou tente novamente mais tarde.`,
    };
  }
};
