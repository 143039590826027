import dayjs from "dayjs";
import styled from "styled-components";

type ServiceOrderCardStepProps = {
  icon: React.ReactNode;
  timestamp?: string | null;
};

const StepContainer = styled.div`
  display: inline-flex;
  align-items: flex-start;
  width: 25%;
`;

const StepText = styled.span`
  font-size: 10px;
  margin-left: 4px;
`;

const ServiceOrderCardStep: React.FC<ServiceOrderCardStepProps> = ({
  icon,
  timestamp,
}) => {
  return (
    <StepContainer>
      {icon}

      <StepText>
        {timestamp
          ? dayjs(timestamp).tz("America/Sao_Paulo").format("DD/MM")
          : "–––"}
      </StepText>
    </StepContainer>
  );
};

export default ServiceOrderCardStep;
