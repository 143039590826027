import styled from "styled-components";

type PageContainerProps = {
  paddingY?: number;
};

const StyledContainer = styled.div<PageContainerProps>`
  height: calc(100vh - 64px);
  overflow: hidden;
  padding: ${(props) => props.paddingY || 0}px 50px;
`;

const PageContainer: React.FC<PageContainerProps> = (props) => {
  return <StyledContainer {...props}>{props.children}</StyledContainer>;
};

export default PageContainer;
