import { Button, Divider, Form, Input, InputNumber, Row, Select } from "antd";
import { useNa3 } from "na3-react";
import { ApiProduct } from "na3-types";
import { useCallback, useState } from "react";
import { Redirect } from "react-router";
import { PageColumn, PageContainer } from "../../components";
import { getShifts } from "../../helpers";
import { useAuth } from "../../hooks";

const TransfHomePage: React.FC = () => {
  const { dpt } = useAuth();
  const na3 = useNa3();
  const [newTransfDoc, setNewTransfDoc] = useState<{
    values: {
      productCode: string;
      machine: string | undefined;
      productionOrderNumber: number | undefined;
      shiftId: string | undefined;
    };
    errors: {
      productCode: string;
      machine: string;
      productionOrderNumber: string;
      shiftId: string;
    };
  }>({
    values: {
      productCode: "",
      machine: undefined,
      productionOrderNumber: undefined,
      shiftId: undefined,
    },
    errors: {
      productCode: "",
      machine: "",
      productionOrderNumber: "",
      shiftId: "",
    },
  });
  const [newTransfDocApiProduct, setNewTransfDocApiProduct] = useState<{
    value: ApiProduct | undefined;
    fetching: boolean;
    error: string;
  }>({
    value: undefined,
    fetching: false,
    error: "",
  });

  const setNewTransfDocValue = useCallback(
    <T extends keyof typeof newTransfDoc["values"]>(
      key: T,
      value: typeof newTransfDoc["values"][T]
    ) => {
      setNewTransfDoc((curr) => ({
        ...curr,
        values: { ...curr.values, [key]: value },
      }));
    },
    []
  );

  const handleProductCodeChanged = useCallback(
    async (eventOrValue: React.ChangeEvent<HTMLInputElement> | string) => {
      const value =
        typeof eventOrValue === "string"
          ? eventOrValue
          : eventOrValue.target.value;

      if (value.length > 10) {
        return;
      }

      setNewTransfDocValue("productCode", value);

      if (value.length === 10) {
        setNewTransfDocApiProduct({
          value: undefined,
          fetching: true,
          error: "",
        });

        const apiProductRes = await na3.getProductByCode(value);

        if (apiProductRes.error) {
          setNewTransfDocApiProduct({
            value: undefined,
            fetching: false,
            error: apiProductRes.error.message,
          });
        } else {
          setNewTransfDocApiProduct({
            value: apiProductRes.data,
            fetching: false,
            error: "",
          });
        }
      }
    },
    [na3, setNewTransfDocValue]
  );

  return (
    <>
      {dpt ? (
        <PageContainer>
          <Row gutter={48}>
            <PageColumn
              span={8}
              title="Suas Transferências"
              list={[]}
              listLoading={false}
            ></PageColumn>

            <PageColumn span={16} title="Nova Transferência">
              <Form layout="vertical">
                <Form.Item label="Setor">
                  <Input disabled value={dpt.displayName.toUpperCase()} />
                </Form.Item>

                <Form.Item
                  label="Código do produto"
                  hasFeedback
                  validateStatus={
                    newTransfDocApiProduct.error ||
                    newTransfDoc.errors.productCode
                      ? "error"
                      : newTransfDocApiProduct.fetching
                      ? "validating"
                      : newTransfDoc.values.productCode
                      ? "success"
                      : undefined
                  }
                  help={
                    newTransfDocApiProduct.error ||
                    newTransfDoc.errors.productCode ||
                    undefined
                  }
                  required
                >
                  <Input
                    placeholder="Toque para preencher"
                    value={newTransfDoc.values.productCode}
                    onChange={handleProductCodeChanged}
                  />
                </Form.Item>

                {/* <FormInput
                  label="Código do produto"
                  validators={[
                    {
                      checkError: (val) =>
                        val.length !== 10 && "Código do produto inválido",
                    },
                    {
                      checkError: async (val) => {
                        const apiProductRes = await api.get<ApiProduct>(
                          `/rest/novaa3/products?code=${val}`
                        );
                        return apiProductRes.error?.message;
                      },
                    },
                  ]}
                  onChangeText={handleProductCodeChanged}
                /> */}

                {newTransfDocApiProduct.value && (
                  <>
                    <Form.Item label="Produto">
                      <Input
                        disabled
                        value={newTransfDocApiProduct.value.name.toUpperCase()}
                      />
                    </Form.Item>

                    <Divider />

                    <Form.Item
                      label="Máquina"
                      hasFeedback
                      validateStatus={
                        newTransfDoc.values.machine
                          ? "success"
                          : newTransfDoc.errors.machine
                          ? "error"
                          : undefined
                      }
                      help={newTransfDoc.errors.machine || undefined}
                      required
                    >
                      <Select
                        placeholder="Toque para selecionar a máquina"
                        value={newTransfDoc.values.machine}
                        onSelect={(value) =>
                          setNewTransfDocValue("machine", value)
                        }
                      >
                        {Object.values(dpt.machines)
                          .sort((a, b) => a.number - b.number)
                          .map((machine) => (
                            <Select.Option value={machine.id} key={machine.id}>
                              {machine.name.toUpperCase()}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>

                    <Form.Item label="Nº da OP" required>
                      <InputNumber
                        placeholder="Toque para preencher"
                        defaultValue={newTransfDoc.values.productionOrderNumber}
                        onChange={(val) =>
                          setNewTransfDocValue("productionOrderNumber", val)
                        }
                        min={100}
                        max={999}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Turno"
                      hasFeedback
                      validateStatus={
                        newTransfDoc.values.shiftId
                          ? "success"
                          : newTransfDoc.errors.shiftId
                          ? "error"
                          : undefined
                      }
                      help={newTransfDoc.errors.shiftId || undefined}
                      required
                    >
                      <Select
                        placeholder="Toque para selecionar o turno"
                        value={newTransfDoc.values.shiftId}
                        onSelect={(value) =>
                          setNewTransfDocValue("shiftId", value)
                        }
                      >
                        {getShifts().map((shift) => (
                          <Select.Option value={shift.id} key={shift.id}>
                            {shift.id.toUpperCase()}{" "}
                            <small>
                              <em>
                                ({shift.startTime} — {shift.endTime})
                              </em>
                            </small>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item label="Nº do lote">
                      <Input disabled value={""} />
                    </Form.Item>

                    <Divider />

                    <Form.Item label="Quantidade" required>
                      <InputNumber
                        placeholder="Toque para preencher"
                        defaultValue={newTransfDoc.values.productionOrderNumber}
                        onChange={(val) =>
                          setNewTransfDocValue("productionOrderNumber", val)
                        }
                        min={100}
                        max={999}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>

                    <Divider />

                    <Button block type="primary" onClick={() => {}}>
                      Abrir Transferência
                    </Button>
                  </>
                )}
              </Form>
            </PageColumn>
          </Row>
        </PageContainer>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default TransfHomePage;
