export const translateId = (id?: string | null) =>
  id
    ? {
        mecanica: "Mecânica",
        eletrica: "Elétrica",
        predial: "Predial",
        preventiva: "Preventiva",
        corretiva: "Corretiva",
        preditiva: "Preditiva",
        machineAdjustment: "Ajuste de máquina",
        low: "Baixa",
        medium: "Média",
        high: "Alta",
        ticketCreated: "OS criada",
        ticketConfirmed: "OS confirmada",
        solutionStepAdded: "Progresso",
        solutionTransmitted: "Solução transmitida",
        solutionAccepted: "Solução aceita",
        ticketClosed: "OS encerrada",
        solutionRefused: "Solução recusada",
        ticketReopened: "OS reaberta",
        priorityChanged: "Prioridade redefinida",
        ticketEdited: "OS editada",
        poke: "Setor cutucado",
        maintainerChanged: "Responsável redefinido",
      }[id] || id
    : "";
