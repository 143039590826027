import dayjs from "dayjs";
import firebase from "firebase";
import uniqid from "uniqid";
import { ServiceOrder } from "../types";
import { getDevice } from "./device";
import { collectionId } from "./firebase";
import { getPushTokensAsync, sendNotificationAsync } from "./notifications";

export const sortServiceOrders = (docs?: ServiceOrder[]) => {
  const ordersStatusMap: Record<ServiceOrder["status"], number> = {
    pending: 3,
    solving: 2,
    solved: 4,
    closed: 1,
    refused: 0,
  };

  return [...(docs || [])]
    .sort((a, b) => +b.id - +a.id)
    .sort((a, b) => ordersStatusMap[b.status] - ordersStatusMap[a.status]);
};

export const getNextServiceOrderIdAsync = async () => {
  const query = await firebase
    .firestore()
    .collection(collectionId("tickets"))
    .get();
  return (query.size + 1).toString().padStart(4, "0");
};

export const createServiceOrderAsync = async (
  id: ServiceOrder["id"],
  data: Pick<
    ServiceOrder,
    | "username"
    | "dpt"
    | "machine"
    | "description"
    | "interruptions"
    | "team"
    | "maintenanceType"
    | "cause"
    | "additionalInfo"
  >
) => {
  const event = buildServiceOrderEvent("ticketCreated");
  const serviceOrder: ServiceOrder = {
    ...data,
    id,
    createdAt: event.timestamp,
    status: "pending",
    solutionSteps: [],
    events: [event],
    version: process.env.REACT_APP_VERSION || null,
  };

  await firebase
    .firestore()
    .collection(collectionId("tickets"))
    .doc(id)
    .set(serviceOrder);

  /* == TO-DO: Update badge count and send to viewOnly also == */
  sendNotificationAsync({
    to: await getPushTokensAsync("manutencao"),
    title: `[AÇÃO NECESSÁRIA] Nova OS – nº ${serviceOrder.id}`,
    body: `Acesse o app para aceitar: "${serviceOrder.description}"`,
  });
};

export const acceptServiceOrderSolutionAsync = async (
  serviceOrder: ServiceOrder
) => {
  await firebase
    .firestore()
    .collection(collectionId("tickets"))
    .doc(serviceOrder.id)
    .update({ closedAt: dayjs().format(), status: "closed" });

  await pushServiceOrderEventsAsync(serviceOrder.id, [
    { type: "solutionAccepted" },
    { type: "ticketClosed" },
    {
      type: "solutionStepAdded",
      payload: { solutionStep: { type: "solutionAccepted" } },
    },
  ]);

  /* == TO-DO: Update badge count and send to viewOnly also == */
  sendNotificationAsync({
    to: await getPushTokensAsync("manutencao"),
    title: `OS #${serviceOrder.id}`,
    body: `OS encerrada pelo solicitante (${serviceOrder.dpt})`,
  });
};

export const refuseServiceOrderSolutionAsync = async (
  serviceOrder: ServiceOrder,
  payload: { refusalReason: string }
) => {
  await firebase
    .firestore()
    .collection(collectionId("tickets"))
    .doc(serviceOrder.id)
    .update({
      status: "pending",
      refusalReason: payload.refusalReason,
      acceptedAt: null,
      solvedAt: null,
      reopenedAt: dayjs().format(),
      priority: null,
      solution: null,
    });

  await pushServiceOrderEventsAsync(serviceOrder.id, [
    {
      type: "solutionRefused",
      payload: { refusalReason: payload.refusalReason },
    },
    { type: "ticketReopened" },
    {
      type: "solutionStepAdded",
      payload: { solutionStep: { type: "solutionRefused" } },
    },
  ]);

  /* == TO-DO: Update badge count and send to viewOnly also == */
  sendNotificationAsync({
    to: await getPushTokensAsync("manutencao"),
    title: `[AÇÃO NECESSÁRIA] OS #${serviceOrder.id}`,
    body: `Solução recusada pelo solicitante (${serviceOrder.dpt})`,
  });
};

export const buildServiceOrderEvent = <
  T extends ServiceOrder["events"][0]["type"]
>(
  type: T,
  payload?: ServiceOrder["events"][0]["payload"]
): ServiceOrder["events"][0] => ({
  id: uniqid(),
  type,
  timestamp: dayjs().format(),
  device: getDevice(),
  payload: payload || null,
});

export const pushServiceOrderEventsAsync = async (
  orderId: ServiceOrder["id"],
  events:
    | {
        type: ServiceOrder["events"][0]["type"];
        payload?: ServiceOrder["events"][0]["payload"];
      }
    | {
        type: ServiceOrder["events"][0]["type"];
        payload?: ServiceOrder["events"][0]["payload"];
      }[]
) => {
  if (!Array.isArray(events)) {
    events = [events];
  }

  const newEvents = events.map((e) =>
    buildServiceOrderEvent(e.type, e.payload)
  );
  const registeredEvents = (
    await firebase
      .firestore()
      .collection(collectionId("tickets"))
      .doc(orderId)
      .get()
  ).data()!.events as ServiceOrder["events"] | undefined | null;

  await firebase
    .firestore()
    .collection(collectionId("tickets"))
    .doc(orderId)
    .update({
      events: registeredEvents
        ? [...registeredEvents, ...newEvents]
        : newEvents,
    });
};
