import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import styled from "styled-components";

type SpinnerProps = React.ComponentProps<typeof Spin>;

const StyledLoadingOutlined = styled(LoadingOutlined)`
  font-size: 24px;
  margin-bottom: 12px;
`;

const Spinner: React.FC<SpinnerProps> = (props) => {
  return (
    <Spin
      indicator={<StyledLoadingOutlined spin />}
      tip="Carregando..."
      {...props}
    />
  );
};

export default Spinner;
