import firebase from "firebase";
import { collectionId } from "./firebase";

export const getPushTokensAsync = async (
  usernamesOrGetter:
    | string
    | string[]
    | (() => string | string[] | Promise<string | string[]>)
): Promise<string[]> => {
  let usernames: string[] = [];
  if (typeof usernamesOrGetter === "string") {
    usernames = [usernamesOrGetter];
  } else if (Array.isArray(usernamesOrGetter)) {
    usernames = [...usernamesOrGetter];
  } else {
    const getterRes = await usernamesOrGetter();
    if (typeof getterRes === "string") {
      usernames = [getterRes];
    } else {
      usernames = getterRes;
    }
  }

  const docs = await Promise.all(
    usernames.map((uname) =>
      firebase
        .firestore()
        .collection(collectionId("push-tokens"))
        .doc(uname)
        .get()
    )
  );

  let pushTokens: string[] = [];

  docs.forEach((doc) => {
    if (doc.exists) {
      pushTokens = [...pushTokens, ...doc.data()!.tokens];
    }
  });

  return pushTokens;
};

export const sendNotificationAsync = async ({
  to,
  title,
  body,
}: {
  to: string | string[];
  title: string;
  body: string;
  data?: { [key: string]: string };
  badge?: number;
}) => {
  try {
    await fetch("https://exp.host/--/api/v2/push/send", {
      method: "POST",
      body: JSON.stringify({ to, title, body }),
      headers: {
        host: "exp.host",
        accept: "application/json",
        "accept-encoding": "gzip, deflate",
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  } catch (err) {
    console.error(err);
  }
};
