import { Modal } from "antd";
import styled from "styled-components";
import { useData } from "../hooks";
import Spinner from "./Spinner";

const StyledModal = styled(Modal)`
  text-align: center;
`;

const GlobalLoading: React.FC = () => {
  const { loading } = useData();

  return (
    <StyledModal visible={loading} footer={null} closable={false}>
      <Spinner tip="Carregando... Aguarde" />
    </StyledModal>
  );
};

export default GlobalLoading;
