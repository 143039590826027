import { Na3Dpt } from "./na3";
import { FirebaseError } from "./utils";

export const isObject = <T extends string>(
  value: unknown,
  requiredKeys: T[]
): value is Record<T, any> =>
  typeof value === "object" &&
  value !== null &&
  requiredKeys.every((key) => key in value);

export const isFirebaseError = (err: unknown): err is FirebaseError =>
  isObject(err, ["name", "code", "message"]);

export const isShopFloorDpt = (dpt: Na3Dpt): dpt is Na3Dpt<"shop-floor"> =>
  dpt.type === "shop-floor";

export const isDptId = (dptId: string): dptId is Na3Dpt<"shop-floor">["id"] =>
  [
    "kit-automatico",
    "flexografia-papel",
    "flexografia-plastico",
    "extrusao",
    "corte-solda-luva",
    "corte-solda-saco",
    "reciclagem",
    "dobra",
    "off-set",
    "corte",
    "super-kit",
    "kit-manual",
    "ekoplasto",
  ].includes(dptId);
