import { Button, Divider, Modal, Typography } from "antd";
import { useCallback, useState } from "react";
import styled from "styled-components";
import labelLayout from "../../assets/transf-label-layout.svg";
import { ApiLabel } from "../../types";

const LabelLayoutContainer = styled.div`
  position: relative;
  border: 1px solid black;
  border-radius: 6px;
  border-bottom-right-radius: 0px;
`;

const LabelData = styled.code<{ x: number; y: number; w?: number }>`
  position: absolute;
  top: ${(props) => props.y}px;
  left: ${(props) => props.x}px;
  width: ${(props) => props.w && props.w + "px"};
`;

const PrintDataContainer = styled.div`
  text-align: right;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

type TransfLabelPreviewProps = {
  data: Pick<
    ApiLabel,
    | "customerName"
    | "date"
    | "productCode"
    | "productName"
    | "productQuantity"
    | "productUnitAbbreviation"
    | "productUnitName"
    | "batchId"
  > & { copies: number };
  visible: boolean;
  onCancel: () => void;
};

const TransfLabelPreview: React.FC<TransfLabelPreviewProps> = ({
  data,
  visible,
  onCancel,
}) => {
  const [loading, setLoading] = useState(false);

  const handlePrint = useCallback((method: "wifi" | "cable") => {}, []);

  return (
    <Modal
      centered
      visible={visible}
      title="Pré-visualização"
      onOk={() => handlePrint("wifi")}
      onCancel={onCancel}
      okText={loading ? "Solicitando..." : "Imprimir"}
      cancelText="Voltar"
      closable={!loading}
      confirmLoading={loading}
      width={1000}
      footer={
        <ModalFooter>
          <Button onClick={onCancel}>Voltar</Button>
          <div>
            <Button onClick={() => handlePrint("cable")}>
              Imprimir via cabo
            </Button>
            <Button type="primary" onClick={() => handlePrint("wifi")}>
              Imprimir via Wi-Fi
            </Button>
          </div>
        </ModalFooter>
      }
    >
      <LabelLayoutContainer>
        <img src={labelLayout} alt="Layout impresso da etiqueta" />

        <LabelData x={311} y={65}>
          {data.customerName.toUpperCase()}
        </LabelData>

        <LabelData x={780} y={65}>
          {data.date.toUpperCase()}
        </LabelData>

        <LabelData x={46} y={150} w={700}>
          {data.productCode.toUpperCase()} — {data.productName.toUpperCase()}
        </LabelData>

        <LabelData x={780} y={150}>
          {data.productQuantity} {data.productUnitAbbreviation.toUpperCase()}
        </LabelData>

        <LabelData x={46} y={258}>
          {data.batchId.toUpperCase()}
        </LabelData>
      </LabelLayoutContainer>

      <Divider />

      <PrintDataContainer>
        <Typography.Text>Nº de cópias: {data.copies}</Typography.Text>
      </PrintDataContainer>
    </Modal>
  );
};

export default TransfLabelPreview;
